// import logo from './logo.svg';
import './App.css';
// import Photos from './components/photos';
// import Employees from './components/employees';
// import Person from './components/person_list';
// import ProductList from './components/productlist';
import Header from './components/header';
import Sidebar from './components/sidebar';
import Body from './components/body';


function App() {
  return (
    <div className="profile-page">
      <Header />
      <div className='flex'>
      <Sidebar />
      <Body />
      </div>
      {/* <Person name="Ankur" />
      <Person name="John" />
      <Person name="Test" /> */}

      {/* <ProductList />
       <Person /> */}
       {/* <Employees /> */}
      {/* <Photos /> */}
    </div>
  );
}

export default App;

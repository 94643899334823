import React from 'react';
import profilepic from '../images/ankur.jpg';

const Sidebar =() => {
    return (
    <div className='sidebar'>
        <img src={profilepic} className="img-fluid" />

        <div className='sidebar-box'>
            <p className='sidebar-work'><span>Work</span> <hr /></p>

            <div className='mywork-company'>
                <div className='mywork-company-inner'>
                <h2>Infogain PVT. LDT.</h2>
                <span>Primary</span>
            </div>
            <p>Noida, India</p>
            </div>
            <div className='mywork-company'>
                <div className='mywork-company-inner'>
                <h2>Uplers</h2>
                <span>Previous</span>
            </div>
            <p>Ahmedabad, India</p>
            </div>
          
        </div>
        <div className='sidebar-box'>
        <p className='sidebar-work'><span>Skiils</span> <hr /></p>
            <ul>
                <li>Wordpress</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>Boostrap</li>
                <li>PHP</li>
                <li>React</li>
                <li>Javascript</li>
            </ul>
        </div>
    </div>
    )
}
export default Sidebar;